import { PromoCodeLandingBaseFragment } from '@/controllers/promoCode/generated/promoCodeLandingBase.fragment.generated';
import { ROUTES } from '@/controllers/router/router.contants';
import { ConsultationCourses } from '@/components/courseLanding/CourseLanding.constants';
import { LandingPromoCodeSlug, PromoSectionType } from '@/components/landing/Promotions/promotions.typedefs';
import {
  ELLIPSE_COLOR_BLUE, ELLIPSE_COLOR_ORANGE, ELLIPSE_COLOR_PINK,
  ELLIPSE_COLOR_YELLOW,
  PROMO_STYLES,
} from '@/components/landing/Promotions/promotions.constants';
import { IconEmojiFire } from '@/components/ui/IconEmojies/IconEmojiFire';
import { IconEmojiFlagUA } from '@/components/ui/IconEmojies/IconEmojiFlagUA';

export interface PromoObject {
  [key: string]: PromoCodeLandingBaseFragment;
}

interface PromoEventSourceType {
  promoCodeSlug: LandingPromoCodeSlug;
  promoSection: PromoSectionType;
}

export const createPromoCodesObject = (
  array: PromoCodeLandingBaseFragment[],
): PromoObject => (
  array.reduce((acc, obj) => {
    if (obj.slug) {
      return {
        ...acc,
        [obj.slug]: obj,
      };
    }

    return acc;
  }, {})
);

export const getConsultationLink = ({
  promoCodeSlug,
  eventSource,
  courseSlug = ConsultationCourses.GeneralConsultation,
}: {
  promoCodeSlug: LandingPromoCodeSlug;
  eventSource: string;
  courseSlug?: string;
}): string => (
  String.prototype.concat(
    ROUTES.consultation.index,
    `?source=${eventSource}`,
    `&courseSlug=${courseSlug}`,
    `&promoCode=${promoCodeSlug}`,
  )
);

export const getPromoEventSource = ({
  promoCodeSlug,
  promoSection,
}: PromoEventSourceType): string => (
  `${promoCodeSlug}_${promoSection}`
);

export const checkIsWeekendToday = (): boolean => (
  [0, 6].includes(new Date().getDay())
);

export const getPromoStyle = (slug: string) => {
  switch (slug) {
    case '24-discount-ua':
      return PROMO_STYLES.UA;

    default:
      return PROMO_STYLES.Regular;
  }
};

export const getPromoStyleProperties = (promoStyle: PROMO_STYLES) => {
  switch (promoStyle) {
    case PROMO_STYLES.UA:
      return {
        ellipseColorLeft: ELLIPSE_COLOR_BLUE,
        ellipseColorRight: ELLIPSE_COLOR_YELLOW,
        emojiIcon: IconEmojiFlagUA,
        tagStyle: 'bonusUA',
      };

    case PROMO_STYLES.Regular:
    default:
      return {
        ellipseColorLeft: ELLIPSE_COLOR_ORANGE,
        ellipseColorRight: ELLIPSE_COLOR_PINK,
        emojiIcon: IconEmojiFire,
        tagStyle: 'bonusRegular',
      };
  }
};

import React from 'react';
import wrappedGift from '@/images/generated/icons.emoji.wrappedGift.s3Key';
import { FCImage } from '@/components/ui/IconEmojies/typedefs';
import { IconEmojiBase } from '@/components/ui/IconEmojies/IconEmojiBase/IconEmojiBase';
import { IconImage } from '@/components/ui/Image/IconImage';

export const IconEmojiWrappedGift: FCImage = ({
  size = 56,
  className,
  alt = 'Wrapped gift',
}) => (
  <IconEmojiBase className={className}>
    <IconImage
      imageData={wrappedGift}
      width={size}
      height={size}
      alt={alt}
      loading='lazy'
    />
  </IconEmojiBase>
);

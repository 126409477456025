import React, { memo } from 'react';
import { PromoBanner } from '@/components/landing/Promotions';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { IconEmojiWrappedGift } from '@/components/ui/IconEmojies/IconEmojiWrappedGift';
import { IconEmojiStarStruck } from '@/components/ui/IconEmojies/IconEmojiStarStruck';
import { IconEmojiJackOLantern } from '@/components/ui/IconEmojies/IconEmojiJackOLantern';
import { LandingPromoCodeSlug, PromoSectionType } from '@/components/landing/Promotions/promotions.typedefs';
import { getConsultationLink, getPromoEventSource } from '@/components/landing/Promotions/promotions.helpers';
import { ELLIPSE_COLOR_ORANGE, ELLIPSE_COLOR_PINK } from '@/components/landing/Promotions/promotions.constants';
import { useGetPromoCodeExpireTime } from '@/components/landing/Promotions/hooks/useGetPromoCodeExpireTime.hook';
import { useIsHalloweenThemeEnabled } from '../components/PromoBanner/hooks/useIsHalloweenThemeEnabled';

interface Props {
  courseSlug?: string;
  shouldShowRedesigned?: boolean;
}

export const DevopsWeekPromoBanner = memo<Props>((props) => {
  const { courseSlug, shouldShowRedesigned } = props;

  const { t } = useTranslation([I18N_CODES.cta]);

  const promoCodeSlug = LandingPromoCodeSlug.DEVOPS_WEEK_PROMO;
  const eventSource = getPromoEventSource({
    promoCodeSlug,
    promoSection: PromoSectionType.BANNER,
  });

  const isHalloweenThemeEnabled = useIsHalloweenThemeEnabled();

  const DecorIcon = isHalloweenThemeEnabled
    ? IconEmojiJackOLantern
    : IconEmojiStarStruck;

  const promoCodeExpireAt = useGetPromoCodeExpireTime(promoCodeSlug);

  if (!promoCodeExpireAt) {
    return null;
  }

  return (
    <PromoBanner
      promoName={promoCodeSlug}
      source={eventSource}
      TitleIcon={IconEmojiWrappedGift}
      DecorIcon={DecorIcon}
      tagText={t(`${I18N_CODES.cta}:promo_code.banner_title.${promoCodeSlug}`)}
      timerEndAt={promoCodeExpireAt}
      buttonText={t(`${I18N_CODES.cta}:promo_code.banner_button.${promoCodeSlug}`)}
      ellipseColorLeft={ELLIPSE_COLOR_ORANGE}
      ellipseColorRight={ELLIPSE_COLOR_PINK}
      href={getConsultationLink({
        promoCodeSlug,
        eventSource,
        courseSlug,
      })}
      shouldShowRedesigned={shouldShowRedesigned}
    />
  );
});

import React, { memo } from 'react';
import { typography } from '@/components/ui/typography';
import { cn } from '@/lib';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import { useCtaContext } from '@/controllers/cta/Cta.context';
import { FCImage } from '@/components/ui/IconEmojies/typedefs';
import { PromoCardDecorations } from '@/components/landing/Promotions/components/PromoCard/PromoCardDecorations';
import styles from './PromoCard.module.scss';
import { CountdownPromoTimerDeprecated } from '../PromoTimer/CountdownPromoTimerDeprecated';

interface Props {
  promoName: string;
  source: string;
  href?: string;

  title: string;
  description: string;
  buttonText: string;

  ellipseColorLeft: string;
  ellipseColorRight: string;

  TitleIcon: FCImage;
  DecorIcon: FCImage;

  timerEndAt: number;
}

/**
 * Card base component that is used for different promotions.
 * F.e., Brazil Carnival, Black Friday, etc.
 * */
export const PromoCard = memo<Props>((props) => {
  const ctaContext = useCtaContext();

  const {
    source,
    TitleIcon,
    DecorIcon,
    ellipseColorLeft,
    ellipseColorRight,
    title,
    description,
    buttonText,
    timerEndAt,
    href = ctaContext.buildHref({ source }),
  } = props;

  return (
    <div className={styles.card}>
      <div className={styles.titleWrapper}>
        <TitleIcon
          size={22}
          className={cn('mr-8', styles.titleIcon)}
        />

        <h5 className={cn(typography.landingH5, 'text-center')}>
          {title}
        </h5>
      </div>

      <p
        className={cn(typography.landingTextMain, styles.description)}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />

      <Button
        text={buttonText}
        mode={ButtonMode.Secondary}
        size={ButtonSize.Large}
        className="mb-16"
        href={href}
        isInternalLink
      />

      <CountdownPromoTimerDeprecated timerEndAt={timerEndAt} />

      <PromoCardDecorations
        DecorIcon={DecorIcon}
        ellipseColorLeft={ellipseColorLeft}
        ellipseColorRight={ellipseColorRight}
      />
    </div>
  );
});

import React, { FC } from 'react';
import { Modal } from '@/components/ui/Modal';
import { cn } from '@/lib';
import { usePopupAutoOpen } from '@/components/common/ExitPopup/hooks/usePopupAutoOpen.hook';
import styles from './ExitPopup.module.scss';

interface Props {
  isOpen: boolean;
  renderTitle: () => JSX.Element;
  close: () => void;
  open: () => void;
  className?: string;
}

export const ExitPopup: FC<Props> = (props) => {
  const {
    isOpen,
    renderTitle,
    open,
    close,
    children,
    className,
  } = props;

  usePopupAutoOpen({
    isOpen,
    open,
  });

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      className={cn(styles.popup, className)}
      shouldCloseOnEsc
      onRequestClose={close}
      renderTitle={renderTitle}
      titleClassNames={styles.title}
      renderCloseButton={false}
    >
      {children}
    </Modal>
  );
};

import { useFindPromoCodesBySlugs } from '@/controllers/promoCode/promoCode.hooks/useFindPromoCodesBySlugs.hook';
import { LandingPromoCodeSlug } from '@/components/landing/Promotions/promotions.typedefs';
import { createPromoCodesObject, PromoObject } from '@/components/landing/Promotions/promotions.helpers';

export const usePromotionsPromoCodesInfo = (): PromoObject => {
  const promoCodesFetched = useFindPromoCodesBySlugs({
    slugs: Object.values(LandingPromoCodeSlug),
  });

  return createPromoCodesObject(promoCodesFetched.promoCodes);
};

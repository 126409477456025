import { useMemo } from 'react';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { PromoCodeLandingBaseFragment } from '@/controllers/promoCode/generated/promoCodeLandingBase.fragment.generated';
import { usePromoCodesBySlugsQuery } from '../generated/promoCodesBySlugs.query.generated';

interface Options {
  slugs: string[];
}

interface Output {
  promoCodes: PromoCodeLandingBaseFragment[];
  loading: boolean;
}

export const useFindPromoCodesBySlugs = (options: Options): Output => {
  const { language } = useSubDomainContext();

  const { data, loading } = usePromoCodesBySlugsQuery({
    variables: {
      slugs: options.slugs,
      lang: language,
    },
  });

  const promoCodes = useMemo(
    () => data?.promoCodesBySlugs || [],
    [data],
  );

  return {
    promoCodes,
    loading,
  };
};

import React from 'react';
import { cn } from '@/lib';
import { FCImage } from '@/components/ui/IconEmojies/typedefs';
import { PromoCardEllipse } from '@/components/landing/Promotions/components/PromoCard/PromoCardEllipse';
import styles from './PromoCardDecorations.module.scss';

interface Props {
  DecorIcon: FCImage;

  ellipseColorLeft: string;
  ellipseColorRight: string;
}

export const PromoCardDecorations = React.memo<Props>((props) => {
  const {
    DecorIcon,
    ellipseColorLeft,
    ellipseColorRight,
  } = props;

  return (
    <>
      <PromoCardEllipse
        className={cn(styles.decoration, styles.topLeftDecoration)}
        color={ellipseColorLeft}
      />

      <PromoCardEllipse
        className={cn(styles.decoration, styles.bottomRightDecoration)}
        color={ellipseColorRight}
      />

      <div className={cn(styles.decoration, styles.leftTopIconContainer)}>
        <DecorIcon
          size={24}
          className={styles.leftTopIcon}
        />
      </div>

      <div className={cn(styles.decoration, styles.leftBottomIconContainer)}>
        <DecorIcon
          size={16}
          className={styles.leftBottomIcon}
        />
      </div>

      <div className={cn(styles.decoration, styles.rightBottomIconContainer)}>
        <DecorIcon
          size={24}
          className={styles.rightBottomIcon}
        />
      </div>
    </>
  );
});

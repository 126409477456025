import { usePromoCodeBySlugQuery } from '@/controllers/promoCode/generated/promoCodeBySlug.query.generated';
import { PromoCodeLandingFragment } from '@/controllers/promoCode/generated/promoCodeLanding.fragment.generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

interface Options {
  slug: string;
}

interface Output {
  promoCode: PromoCodeLandingFragment | null;
  loading: boolean;
}

export const useFindPromoCodeBySlug = (options: Options): Output => {
  const { language } = useSubDomainContext();

  const { data, loading } = usePromoCodeBySlugQuery({
    variables: {
      slug: options.slug,
      lang: language,
    },
  });

  const promoCode = data?.promoCodeBySlug || null;

  return {
    promoCode,
    loading,
  };
};

import { SubDomains } from '@/constants/subdomains';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { CourseType, PromoCodeStatus } from '@/controllers/graphql/generated';
import { PageType } from '@/components/landing/Promotions/promotions.typedefs';
import { useCtaContext } from '@/controllers/cta/Cta.context';
import { PromoCodeLandingBaseFragment } from '@/controllers/promoCode/generated/promoCodeLandingBase.fragment.generated';

interface Options {
  domain: SubDomains;
  courseType: CourseType;
  professionSlug?: string; // Pass page type while using with allowedProfessions
  promoCode?: PromoCodeLandingBaseFragment;
  allowedPageTypes?: PageType[]; // Allowed for all by default
  allowedProfessionSlugs?: string[]; // Allowed for all by default
  forbiddenProfessionSlugs?: string[]; // Opposite to previous
  additionalCondition?: boolean;
  allowedCourseType?: CourseType;
}

export const useShouldShowPromo = (
  options: Options,
) => {
  const {
    domain,
    courseType,
    promoCode,
    allowedPageTypes,
    professionSlug,
    allowedProfessionSlugs,
    forbiddenProfessionSlugs,
    additionalCondition = true,
    allowedCourseType,
  } = options;

  const { subDomain } = useSubDomainContext();
  const { pageType } = useCtaContext();

  const isCorrectDomain = subDomain === domain;
  const isPromoCodeActive = promoCode?.status === PromoCodeStatus.Active;

  const isAllowedPageType = allowedPageTypes
    ? Boolean(
      pageType
      && allowedPageTypes.includes(pageType),
    )
    : true;

  const isAllowedProfessionSlug = allowedProfessionSlugs
    ? Boolean(
      professionSlug
      && allowedProfessionSlugs.includes(professionSlug),
    )
    : true;

  const isForbiddenProfessionSlug = forbiddenProfessionSlugs
    ? Boolean(
      professionSlug
      && forbiddenProfessionSlugs.includes(professionSlug),
    )
    : false;

  const isAllowedCourseType = allowedCourseType
    ? courseType === allowedCourseType
    : true;

  return (
    isCorrectDomain
    && isPromoCodeActive
    && isAllowedPageType
    && isAllowedProfessionSlug
    && !isForbiddenProfessionSlug
    && additionalCondition
    && isAllowedCourseType
  );
};

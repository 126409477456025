import { useBodyScrollLock } from '@/hooks/useBodyScrollLock';
import { emptyFunction } from '@/lib/helpers/functional';
import { useIsOpenState } from './useIsOpenState';

interface Options {
  initialState?: boolean;
  onChangeState?: (v: boolean) => void;
}

const DEFAULT_OPTIONS: Required<Options> = {
  initialState: false,
  onChangeState: emptyFunction,
};

export const useIsOpenStateWithBlockPageScroll = (
  options: Options = DEFAULT_OPTIONS,
): ReturnType<typeof useIsOpenState> => {
  const {
    initialState = DEFAULT_OPTIONS.initialState,
    onChangeState = DEFAULT_OPTIONS.onChangeState,
  } = options;

  const { setActive } = useBodyScrollLock(false);

  return useIsOpenState({
    initialState,
    onChangeState: (isOpen) => {
      setActive(isOpen);

      onChangeState(isOpen);
    },
  });
};

import React, { FC, SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & {
  color: string;
};

export const PromoCardEllipse: FC<Props> = (props) => {
  const { color, ...svgProps } = props;

  const gradientId = `promo-card-ellipse-gradient-${color}`;

  return (
    <svg
      {...svgProps}
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_453_7959)">
        <g filter="url(#filter1_f_453_7959)">
          <circle
            cx="120"
            cy="120"
            r="56"
            transform="rotate(180 120 120)"
            fill={`url(#${gradientId})`}
            fillOpacity="0.8"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_453_7959"
          x="0"
          y="0"
          width="240"
          height="240"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="32"
            result="effect1_foregroundBlur_453_7959"
          />
        </filter>
        <filter
          id="filter1_f_453_7959"
          x="0"
          y="0"
          width="240"
          height="240"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="32"
            result="effect1_foregroundBlur_453_7959"
          />
        </filter>
        <linearGradient
          id={gradientId}
          x1="151.332"
          y1="277.85"
          x2="2.50499"
          y2="217.566"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>

  );
};

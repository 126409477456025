import React, {
  FC,
} from 'react';
import { ExitPopup } from '@/components/common/ExitPopup';
import { cn } from '@/lib';
import { I18N_CODES } from '@/lib/constants/general';
import { Button } from '@/components/ui/Button';
import { useTranslation } from '@/middleware/i18n';
import chubbsFormulas from '@/images/generated/landing.pageExitPopup.chubbsFormulas.s3Key';
import { IconImage } from '@/components/ui/Image/IconImage';
import { useCtaContext } from '@/controllers/cta/Cta.context';
import { useIsOpenStateWithBlockPageScroll } from '@/hooks/useIsOpenStateWithBlockPageScroll';
import { analyticsSDK } from '@/controllers/analytics';
import styles from './PageExitPopup.module.scss';

interface Props {
  source: string;
}

export const PageExitPopup: FC<Props> = (props) => {
  const {
    source = 'exit_popup',
  } = props;

  const { t } = useTranslation([I18N_CODES.home]);

  const {
    isOpen,
    close,
    open,
  } = useIsOpenStateWithBlockPageScroll();

  const { buildHref } = useCtaContext();
  const ctaHref = buildHref({ source });

  const handleClose = () => {
    close();

    analyticsSDK.landing.sendExitPopupClosedEvent({
      source,
    });
  };

  const handleOpen = () => {
    open();

    analyticsSDK.landing.sendExitPopupOpenedEvent({
      source,
    });
  };

  const handleButtonClick = () => {
    analyticsSDK.landing.sendButtonClickedEvent({
      source,
    });
  };

  return (
    <ExitPopup
      renderTitle={() => (
        <>
          <div className={styles.imageContainer}>
            <IconImage
              imageData={chubbsFormulas}
              className={styles.image}
              objectFit="contain"
              width={chubbsFormulas.fallback.width}
              height={chubbsFormulas.fallback.height}
              loading="lazy"
            />
          </div>

          <h2 className={styles.popupTitle}>
            {t(`${I18N_CODES.home}:exit-popup.title`)}
          </h2>
        </>
      )}
      className={styles.modalContainer}
      open={handleOpen}
      close={handleClose}
      isOpen={isOpen}
    >
      <p className={cn(styles.mainText, 'mb-24')}>
        {t(`${I18N_CODES.home}:exit-popup.text`)}
      </p>

      <div className={styles.buttonsContainer}>
        <Button
          mode={Button.mode.BrandSecondary}
          size={Button.size.Large}
          hasFullWidth
          onClick={handleClose}
          text={t(`${I18N_CODES.home}:exit-popup.secondary-button-text`)}
        />

        <Button
          size={Button.size.Large}
          mode={Button.mode.BrandPrimary}
          hasFullWidth
          href={ctaHref}
          text={t(`${I18N_CODES.home}:exit-popup.primary-button-text`)}
          onClick={handleButtonClick}
          isInternalLink
        />
      </div>
    </ExitPopup>
  );
};

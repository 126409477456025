import React, { FC } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { PromoTimerHelpers } from '@/components/landing/Promotions/components/PromoTimer/PromoTimer.helpers';
import styles from './PromoTimerDeprecated.module.scss';

interface Props {
  remainingSeconds: number;
  className?: string;
}

export const PromoTimerTemplateDeprecated: FC<Props> = (props) => {
  const {
    className,
    remainingSeconds,
  } = props;

  const { t } = useTranslation([I18N_CODES.cta]);

  const {
    days,
    hours,
    minutes,
    seconds,
  } = PromoTimerHelpers.formatTime(remainingSeconds);

  return (
    <div className={cn(typography.landingH6, styles.container, className)}>
      <span>
        <span className={styles.number} data-qa='cta-time-days'>
          {days}
        </span>

        {t(`${I18N_CODES.cta}:cta_time_days_short`)}
      </span>

      <span className={styles.colon}>
        :
      </span>

      <span>
        <span className={styles.number} data-qa='cta-time-hours'>
          {hours}
        </span>

        {t(`${I18N_CODES.cta}:cta_time_hours_short`)}
      </span>

      <span className={styles.colon}>
        :
      </span>

      <span>
        <span className={styles.number} data-qa='cta-time-minutes'>
          {minutes}
        </span>

        {t(`${I18N_CODES.cta}:cta_time_minutes_short`)}
      </span>

      <span className={styles.colon}>
        :
      </span>

      <span>
        <span className={styles.number}>
          {seconds}
        </span>

        {t(`${I18N_CODES.cta}:cta_time_seconds_short`)}
      </span>
    </div>
  );
};

import React, { memo } from 'react';
import dynamic from 'next/dynamic';
import { PromoBannerBaseProps } from '@/components/landing/Promotions/components/PromoBanner/PromoBanner.typedefs';
import { useIsHalloweenThemeEnabled } from './hooks/useIsHalloweenThemeEnabled';

const PromoBannerDeprecated = dynamic(
  () => import('./PromoBannerDeprecated')
    .then((mod) => mod.PromoBannerDeprecated),
);

const PromoBannerRedesigned = dynamic(
  () => import('./PromoBannerRedesigned')
    .then((mod) => mod.PromoBannerRedesigned),
);

const HalloweenPromoBanner = dynamic(
  () => import('./HalloweenPromoBanner')
    .then((mod) => mod.HalloweenPromoBanner),
);

type Props = PromoBannerBaseProps & {
  shouldShowRedesigned?: boolean;
};

export const PromoBanner = memo<Props>((props) => {
  const { shouldShowRedesigned, ...rest } = props;

  const isHalloweenThemeEnabled = useIsHalloweenThemeEnabled();

  if (shouldShowRedesigned && !isHalloweenThemeEnabled) {
    return <PromoBannerRedesigned {...rest} />;
  }

  if (shouldShowRedesigned && isHalloweenThemeEnabled) {
    return <HalloweenPromoBanner {...rest} />;
  }

  return <PromoBannerDeprecated {...rest} />;
});

import { getTimerExpiredAtTime } from '@/components/consultation/consultation.helpers';
import { useFindPromoCodeBySlug } from '@/controllers/promoCode/promoCode.hooks/useFindPromoCodeBySlug.hook';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';

export const useGetPromoCodeExpireTime = (promoCodeSlug: string) => {
  const { loading, promoCode } = useFindPromoCodeBySlug({
    slug: promoCodeSlug,
  });

  const [promoFirstInteractionTime] = useLocalStorage(
    LOCAL_STORAGE_KEYS.promoFirstInteractionTime(promoCodeSlug),
    Date.now(),
  );

  if (loading || !promoCode) {
    return null;
  }

  return getTimerExpiredAtTime({
    promoCode,
    promoFirstInteractionTime,
  });
};

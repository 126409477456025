import { useRef } from 'react';
import { Emitter } from '@/lib/Emitter';

export enum ExitPopupEventType {
  ResetExitPopupTimeout = 'reset_exit_popup_timeout',
}

export type ExitPopupEvent = {
  type: ExitPopupEventType;
};

export type ExitPopupEmitter = Emitter<ExitPopupEvent>;

export const useExitPopupEventEmitter = (): ExitPopupEmitter => {
  const exitPopupEmitterRef = useRef<ExitPopupEmitter>(
    new Emitter<ExitPopupEvent>(),
  );

  return exitPopupEmitterRef.current;
};

import React, { memo, useEffect, useState } from 'react';
import { SECOND } from '@/constants';
import { PromoTimerTemplateDeprecated } from '@/components/landing/Promotions/components/PromoTimer/PromoTimerTemplateDeprecated';

type Props = {
  timerEndAt: number;
  className?: string;
};

export const CountdownPromoTimerDeprecated = memo<Props>((props) => {
  const {
    timerEndAt,
    className,
  } = props;

  const defaultRemainingSeconds = Math.max(
    0,
    Math.floor((timerEndAt - Date.now()) / SECOND),
  );

  const [
    remainingSeconds,
    setRemainingSeconds,
  ] = useState(defaultRemainingSeconds);

  useEffect(() => {
    const interval = window.setInterval(() => {
      const now = Date.now();

      setRemainingSeconds(Math.max(0, Math.floor((timerEndAt - now) / SECOND)));

      if (remainingSeconds <= 0) {
        clearInterval(interval);
      }
    }, SECOND);

    return () => {
      clearInterval(interval);
    };
  }, [
    timerEndAt,
    defaultRemainingSeconds,
    remainingSeconds,
  ]);

  return (
    <PromoTimerTemplateDeprecated
      className={className}
      remainingSeconds={remainingSeconds}
    />
  );
});

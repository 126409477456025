export type EmitterEvent<
  Type extends string = string,
  Payload = unknown
> = Payload extends undefined
  ? {
    type: Type;
    payload: Payload;
  }
  : {
    type: Type;
  };
export type EmitterListener<Event> = (event: Event) => void;

export class Emitter<Event extends EmitterEvent> {
  private listeners: Map<Event['type'], EmitterListener<Event>[]> = new Map();

  listen(eventType: Event['type'], listener: EmitterListener<Event>) {
    if (this.listeners.has(eventType)) {
      this.listeners.get(eventType)!.push(listener);
    } else {
      this.listeners.set(eventType, [listener]);
    }
  }

  notify(event: Event) {
    const listeners = this.listeners.get(event.type);

    if (!listeners) {
      return;
    }

    [...listeners].forEach((listener) => {
      listener(event);
    });
  }

  unsubscribe(eventType: Event['type'], listener: EmitterListener<Event>) {
    const eventListeners = this.listeners.get(eventType);
    const eventListenerIndex = eventListeners?.indexOf(listener);

    if (eventListenerIndex !== undefined && eventListenerIndex !== -1) {
      eventListeners?.splice(eventListenerIndex, 1);
    }

    if (!eventListeners?.length) {
      this.listeners.delete(eventType);
    }
  }
}
